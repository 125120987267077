import type { TransformerOption } from '@cld-apis/types'
import { buildImageUrl } from 'cloudinary-build-url'

export function useCloudinary(fullPath: string, options: TransformerOption) {
  return buildImageUrl(fullPath, {
    cloud: {
      cloudName: 'dmzcdenki',
    },
    transformations: options,
  })
}
